import React from 'react'
import styled from "@emotion/styled"

const Container = styled.div`
  //background-color: rgba(0,0,0,0.05);
`
export default ({children}) => (
    <Container className={'container-fluid'}>
      {children}
    </Container>
)