import BlockContent from '@sanity/block-content-to-react'

export default (props) => {

  // const {style = 'normal'} = props.node;

  // if (style === 'h2') {
  //   // console.log('h2');
  //   return <div>{props.children}</div>
  // }
  //
  // if (/^h\d/.test(style)) {
  //   // This would replace with a custom element for head styles;
  //   const level = style.replace(/[^\d]/g, '')
  //   return React.createElement(style, {className: `heading-${level}`}, props.children)
  // }
  //
  // if (style === 'blockquote') {
  //   return <blockquote>- {props.children}</blockquote>
  // }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}
