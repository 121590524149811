import React, {useState, useCallback} from "react"
import styled from "@emotion/styled";
import {useTheme} from 'emotion-theming'
import Gallery from "react-photo-gallery"
import Carousel, {Modal, ModalGateway} from "react-images"
//
const GallerySection = styled.div`
    margin: ${props => props.gutter}px;
`

//.react-images__blanket {
//  background-color: rgba(255,255,255, 0.95) !important;
//}
//.react-images__header_button,
//.react-images__navigation svg,
//.react-images__footer__caption,
//.react-images__footer__count  {
//  color: black !important;
//}
//
//.react-images__navigation button {
//  background: rgba(0,0,0,0.1) !important;
//}
//.react-images__header {
//  background: rgba(255, 255, 255, 0) linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) !important;
//}
//.react-images__footer {
//  background: rgba(255, 255, 255, 0) linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5)) !important;
//}

const customStyles = {
  blanket: (base, state) => ({
    ...base,
    backgroundColor: state.modalProps.theme.backgroundColor,
  }),
  container: (base, state) => ({
    ...base,
    backgroundColor: 'transparent',
  }),
  header: (base, state) => ({
    ...base,
    color: state.carouselProps.theme.color,
    background: 'none !important',
    backgroundColor: 'white !important',
    '& button': {
      color: state.carouselProps.theme.button.normal
    },
    '& button:hover': {
      color: state.carouselProps.theme.button.hover
    },
    '& button:active': {
      border: 0,
      outline: 0,
    }
  }),
  footer: (base, state) => ({
    ...base,
    color: state.carouselProps.theme.color,
    background: 'none !important',
    backgroundColor: 'white !important',
  }),
  navigation: (base, state) => ({
    ...base,
    '& button': {
      backgroundColor: state.carouselProps.theme.button.normal
    },
    '& button:hover': {
      backgroundColor: state.carouselProps.theme.button.hover
    },
    '& button:active': {
      border: 0,
      outline: 0,
    }
  })
}

export default ({photos, gutter}) => {

  gutter = Number(gutter);

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, {photo, index}) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const theme = useTheme();
  return (
      <GallerySection className={'gallery'} gutter={gutter}>
        {/*className={styles.gallerySection}*/}
        <Gallery photos={photos} onClick={openLightbox} margin={gutter}/>
        <ModalGateway>
          {viewerIsOpen ? (
              <Modal onClose={closeLightbox} allowFullscreen={false} theme={theme.lightBox} styles={customStyles}>
                <Carousel
                    currentIndex={currentImage}
                    styles={customStyles}
                    theme={theme.lightBox}
                    views={photos.map(x => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title
                    }))}
                />
              </Modal>
          ) : null}
        </ModalGateway>
      </GallerySection>
  )
}