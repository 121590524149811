import React from 'react'
import styled from '@emotion/styled'
import Link from '../components/Link'
import {FiExternalLink} from 'react-icons/fi';
import {graphql} from "gatsby";
import PageSection from "../components/PageSection";

const Section = styled.div`
  margin: ${props => props.theme.section.margin};
  text-align: center;
`
const SectionTitle = styled.h1`
  text-transform: uppercase;
  margin-bottom: 48px;
`
const FriendsSection = styled.ul`
  list-style: none;
  padding: 0;
`
const Friend = styled.li`
  margin-bottom: 36px;
  a {
    text-decoration: none;
  }
  a:hover {
    color: inherit;
    font-weight: 700;
  }
`
const Title = styled.h5`
  margin-bottom: 0;
   text-transform: uppercase; 
   svg {
    margin-left: 12px;
   }
`
const Description = styled.div`
  //padding-top: 6px;
`

export default ({friends}) => {
  return (
      <PageSection type={'friends'}>
      <Section>
        <SectionTitle>Friends</SectionTitle>
        <FriendsSection>
          {friends.map((friend, key) => friend._id ?
              <Friend key={key}>
                <Link to={friend.website.url}><Title>{friend.website.title}<FiExternalLink/></Title></Link>
                {friend.description ? <Description>{friend.description}</Description> : null}
              </Friend> : null)}
        </FriendsSection>
      </Section>
      </PageSection>
  )
}
export const query = graphql`
    fragment FriendsSectionFragment on SanityFriendsSection {
        _key
        _type
        friends {
            _id
            description {
                de
                en
            }
            website {
                title {
                    de
                    en
                }
                url
            }
        }
    }
`