import React, {useRef} from "react";
import classNames from "classnames";

export default ({className, type, children}) => {
  const sectionClassName = `${type}-page-section`.replace('Section', '')
  //`page-section-${type}`,
  return (
      <section className={classNames(className, 'page-section', sectionClassName)}>
        <div className={'content-wrapper'}>
          <div className={'content'}>
            {children}
          </div>
        </div>
      </section>
  )
}