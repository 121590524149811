import React from 'react'
import styled from '@emotion/styled'
import BlockContent from '@sanity/block-content-to-react'

import serializers from "../serializers";

const StyledBlockContent = styled.div`
`
export default ({blocks}) => {
  if (!blocks) {
    return null
  }
  return (
      <StyledBlockContent>
        <BlockContent blocks={blocks} serializers={serializers}/>
      </StyledBlockContent>
  )
}