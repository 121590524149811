import React from "react"
import styled from "@emotion/styled"
import classNames from "classnames"

const Col = styled.div`
`
export default ({xs, sm, md, lg, xl, valign, className, style, children}) => {
    let classes = classNames(
        xs ? `col-${xs}` : null,
        sm ? `col-sm-${sm}` : null,
        md ? `col-md-${md}` : null,
        lg ? `col-lg-${lg}` : null,
        xl ? `col-xl-${xl}` : null,
        // valign? 'align-self-center' : null,
        {

    });
    classes = classes ? classes : 'col'
    classes = classNames(className, classes)
    return (
        <Col className={classes} style={style}>{children}</Col>
)}