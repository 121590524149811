import React, {useState, useEffect} from 'react'
import {Helmet} from "react-helmet";
//
import LocalizationProvider from '../locale/LocalizationProvider';
import SanityProvider from "../sanity/SanityProvider";
import ResponsiveDevBar from "./ResponsiveDevBar";

export default ({siteContext, page, children}) => {
  const [isPreloaded, setIsPreloaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsPreloaded(true)
    }, 100)
  },[]);
  return (
      <div className={isPreloaded ? 'layout' : 'layout layout--loading'}>
        <Helmet>
          <title>{page.title}</title>
        </Helmet>
        {/*<SanityProvider config={siteContext.sanity}>*/}
          {children}
          {siteContext.showResponsiveBar ? <ResponsiveDevBar/> : null}
        {/*</SanityProvider>*/}
      </div>

      // <SanityProvider config={options.sanity}>
      //   <LocalizationProvider config={require(GATSBY_LOCALE_LANGUAGE_PATH)} url={props.props.location.pathname}>
      //     {props.element}
      //   </LocalizationProvider>
      // </SanityProvider>
  )
}