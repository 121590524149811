import React from 'react'
import styled from '@emotion/styled'
import ArticleList from "./ArticleList";

const News = styled.section`
    padding: 24px 24px;
    //margin: 0 24px;
    background-color: #fcfcfc;
`
const Title = styled.h2`
    font-family: ${props => props.theme.headerFont};
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 24px;
`
const Articles = styled.div`
`
export default ({title, children}) => {
  return (
      <News>
        <Title>{title}</Title>
        <Articles>
          <ArticleList gutter={24}>
            {children}
          </ArticleList>
        </Articles>
      </News>
  )
}