import React from 'react'
import styled from '@emotion/styled'
import {useLocale} from "../locale";
import {Col} from './Grid'
import LanguageSelector from "./LanguageSelector";
import Link from './Link'

export const StyledLinks = styled(Col)`
  padding: 24px 0;
  text-align: center;
  a {
    padding-right: 6px;
    text-transform: uppercase;
    text-decoration: none;
    color:${props => props.theme.textColor};
    
    &:after {
      position: relative;
      top: -1px;
      content: '|';
      padding-left: 6px;
    }
    
    &:nth-of-type(3) {
      ${props => props.theme.mq({
        paddingRight: ['0', '6px', '6px', '6px']
      })};
      &:after {
        ${props => props.theme.mq({
          content: ['""', '"|"', '"|"', '"|"']
        })};
      }
    }
  }
`
export const StyledLanguageSelector = styled(LanguageSelector)`
  ${props => props.theme.mq({
    display: ['block', 'inline', 'inline', 'inline']
  })};
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: inline;
    &:last-child {
      a:after {
        content: '';
      }
      a {
        padding-right: 0;
      }
    }
    &.selected {
      font-weight: 700;
    }
  }
  a {
    &:after {
      position: relative;
      top: -1px;
      content: '-';
      font-weight: 400;
      padding-left: 6px;
    }
  }
`

export const Links = ({links}) => {
  const locale = useLocale().locale;
  return (
      <StyledLinks>{links.map((link, key) =>
          <Link key={key} to={link.url[locale]}>{link.title[locale]}</Link>
      )}<StyledLanguageSelector/>
      </StyledLinks>
  )
}