import React from "react";
import styled from "@emotion/styled";
import Flickity from 'react-flickity-component'
import 'flickity/css/flickity.css'
import 'flickity-fade/flickity-fade.css'

/**
 * Important! Prevent Gatsby SSR window not defined error
 */
if (typeof window !== 'undefined') {
  require('flickity-fade/flickity-fade')
  require('flickity-imagesloaded')
}

// const ImageSlider = styled.div`
//   height: ${props => props.height};
//   min-height: ${props => props.height};
//   width: 100%;
//   .flickity-enabled, .flickity-viewport, .flickity-slider {
//     height: 100% !important;
//     //width: 100% !important;
//     z-index: 0; // Fix 50% width flicker in fade mode
//   }
//   .flickity-slider {
//     transform:none !important; // Fix 50% width flicker in fade mode
//   }
//   .flickity-page-dots {
//     ${props => props.theme.mq({display: ['none', 'block']})};
//     bottom: 25px;
//     .dot {
//       background-color: white;
//     }
//   }
//   .gatsby-image-wrapper {
//     // height: ${props => props.height};
//     //width: 100%;
//     img, picture {
//       // height: ${props => props.height};
//       // width: 100%;
//     }
//   }
// `
const ImageSlider = styled.div`
  //background-color: red;
  margin-bottom: 25px;
  .flickity-page-dots {
    bottom: -20px;
    .dot {
      width: 6px;
      height: 6px;
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 200px;
    img, picture {
    width: 100%;
    height: 200px;
    object-fit: contain !important;
    }
  }
`

export default ({children}) => {
  const options = {
    cellAlign: 'left',
    contain: true,
    prevNextButtons: false,
    pageDots: children.length > 1,
    draggable: '>1',
  }
  return (
      <ImageSlider>
        <Flickity options={options}>
          {children}
        </Flickity>
      </ImageSlider>
  )
}