/**
 * Loaded via gatsby-config and merged into siteMetadata GraphQL node
 * @type {{defaultLangKey: string, langs: [string, string]}}
 */

module.exports = {
    langs: ['de', 'en'], // DO NO RENAME THIS KEY, it appears tp be used by i18N plugin
    defaultLangKey: 'de', // DO NO RENAME THIS KEY, it appears tp be used by i18N plugin
    en: require('./en'),
    de: require('./de')
};