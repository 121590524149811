import React from 'react'
import styled from '@emotion/styled'

const Link = styled.a`
  color: ${props => props.theme.textColor};
  text-decoration: underline;
`
export default ({to, children}) => {
  return (
      <Link href={to} target={'_blank'} rel={"noopener noreferrer"}>{children}</Link>
  )
}