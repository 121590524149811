module.exports = {
  "siteLayout": require("./siteLayout"),
  "connectedAccounts": [
    {
      "screenname": "Instagram",
      "profileUrl": "http://instagram.com/jeanne.dees.art/",
    }
  ],
  "showResponsiveBar": false,
  "sanity": {
    "projectId": 'mppsmaef',
    "dataset": 'production',
  },
  "mailchimp": {
    "url": "https://jeannedees.us20.list-manage.com/subscribe/post?u=f736acf8d17737c91c046f048&amp;id=9c5d0164ba",
  },
  "languages": require("../data/languages"),
}