import React from 'react'
import styled from "@emotion/styled"
import {Container, Row, Col} from "react-bootstrap";

// $grid-breakpoints: (
//     xs: 0,
//     sm: 576px,
//     md: 768px,
//     lg: 992px,
//     xl: 1200px
// ) !default;

/**
 * iPhone 6       | XS | SM
 * iPhone 6+      | XS | SM
 * iPhone XS      | XS | MD
 * iPhone 11      | XS | MD
 * iPad           | MD | LG
 * iPad Pro
 * iPad Pro 12.9  | LG | XL
 */

const Bar = styled.section`

`

const BootstrapRow = styled(Row)`
  .d-block,
  .d-sm-block,
  .d-md-block,
  .d-lg-block,
  .d-xl-block  
  {
    font-size: 10px;
    color: white;
    background-color: red;
    text-align: center;
  }
`
const FacepaintRow = styled(Row)`
  .xs,
  .sm,
  .md,
  .lg,
  .xl  
  {
      font-size: 10px;
    color: white;
    background-color: green;
    text-align: center;
  }
  .xs {
    ${props => props.theme.mq({
  display: ['block', 'none', 'none', 'none', 'none'],
})}
  }
  .sm {
    ${props => props.theme.mq({
  display: ['none', 'block', 'none', 'none', 'none'],
})}
  }
  .md {
    ${props => props.theme.mq({
  display: ['none', 'none', 'block', 'none', 'none'],
})}
  }
    .lg {
    ${props => props.theme.mq({
  display: ['none', 'none', 'none', 'block', 'none'],
})}
    }
    .xl {
    ${props => props.theme.mq({
  display: ['none', 'none', 'none', 'none', 'block'],
})}
  }
`

export default () => {
  return (
      <Bar>
        <Container>
          <BootstrapRow>
            <Col xs={12} className={'d-block d-sm-none d-md-none d-lg-none d-xl-none'}>BOOTSTRAP|XS</Col>
            <Col xs={12} className={'d-none d-sm-block d-md-none d-lg-none d-xl-none'}>BOOTSTRAP|SM</Col>
            <Col xs={12} className={'d-none d-sm-none d-md-block d-lg-none d-xl-none'}>BOOTSTRAP|MD</Col>
            <Col xs={12} className={'d-none d-sm-none d-md-none d-lg-block d-xl-none'}>BOOTSTRAP|LG</Col>
            <Col xs={12} className={'d-none d-sm-none d-md-none d-lg-none d-xl-block'}>BOOTSTRAP|XL</Col>
          </BootstrapRow>
          <FacepaintRow>
            <Col xs={12} className={'xs'}>FACEPAINT|XS</Col>
            <Col xs={12} className={'sm'}>FACEPAINT|SM</Col>
            <Col xs={12} className={'md'}>FACEPAINT|MD</Col>
            <Col xs={12} className={'lg'}>FACEPAINT|LG</Col>
            <Col xs={12} className={'xl'}>FACEPAINT|XL</Col>
          </FacepaintRow>
        </Container>
      </Bar>
  )
}