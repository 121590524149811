import React from "react";
import styled from "@emotion/styled";
import Flickity from 'react-flickity-component'
import 'flickity/css/flickity.css'
import 'flickity-fade/flickity-fade.css'

/**
 * Important! Prevent Gatsby SSR window not defined error
 */
if (typeof window !== 'undefined') {
  require('flickity-fade/flickity-fade')
  require('flickity-imagesloaded')
}

const Carousel = styled.div`
  height: ${props => props.height};
  min-height: ${props => props.height};
  width: 100%;
  .flickity-enabled, .flickity-viewport, .flickity-slider {
    height: 100% !important;
    ////width: 100% !important;
    //z-index: 0; // Fix 50% width flicker in fade mode
  }
  .flickity-slider {
    // Enabling this next fix, will break single images displayed of screen -50
    //transform:none !important; // Fix 50% width flicker in fade mode
  }
  .flickity-page-dots {
    ${props => props.theme.mq({display: ['none', 'block']})};
    bottom: 25px;
    .dot {
      background-color: white;
    }
  }
  .gatsby-image-wrapper {
    height: ${props => props.height};
    img, picture {
      height: ${props => props.height};
      width: 100%;
    }
  }
`
export default ({children, fade}) => {
  const options = {
    fade: fade || false,
    // wrapAround: false,
    autoPlay: true,
    pauseAutoPlayOnHover: true,
    prevNextButtons: false,
    imagesLoaded: true,
    setGallerySize: false,
    pageDots: children.length > 1,
  }
  return (
      <Carousel height={'100%'}>
        <Flickity options={options}>
          {children}
        </Flickity>
      </Carousel>
  )
}