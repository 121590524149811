import React, {useRef} from "react"
import styled from '@emotion/styled'
import {graphql} from "gatsby"
import PageSection from "../components/PageSection";

export default ({navigations, covers}) => {
  const nextSectionRef = useRef(null)
  return (
  <PageSection type={'cover'}>
    <Cover covers={data.page.covers}
           logo={<Img fluid={data.logo.childImageSharp.fluid} durationFadeIn={0}/>}
           scrollTo={nextSectionRef}/>
    <div ref={nextSectionRef}/>
  </PageSection>
  )
}

// export const query = graphql`
//     fragment CoverSectionFragment on SanityCoverSection {
//         _key
//         _type
//         covers {
//             photos {
//                 asset {
//                     _id
//                     url
// # Only works with gatsby-source-sanity-transform-images (which causes createPages loop)
// #                    localFile(width: 1400) {
// #                        publicURL
// #                        childImageSharp {
// #                            fluid {
// #                                ...GatsbyImageSharpFluid
// #                            }
// #                        }
// #                    }
//                 }
//             }
//         }
//     }
// `