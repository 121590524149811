import React from 'react'
import Block from '../components/BlockContent'

export default ({node}) => {
  // console.log(node);
  if (!node || !node.de) {
    return null
  }
  return (
      <Block block={node.de[0]} />
  )
}
