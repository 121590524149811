import React, {useState} from "react"
import styled from "@emotion/styled";
import {FaBars} from 'react-icons/fa';
import {useLocale} from "@shockventures/gatsby-theme-common";
import Link from "@shockventures/gatsby-theme-common/src/components/Link";

const height = 80;

const Nav = styled.nav`
  position: absolute;
  bottom: 96px;
  width: 100%;
`
const Bar = styled.div`
  position: absolute;
  right: 0;
  height: ${height}px;
  background-color: white;
`
const NavItems = styled.ul`
    margin: 0;
    padding-left: 234px;
    padding-right: 0;
    text-align: right;
    li {
      display: inline-block;
      list-style-type: none;
      height: ${height}px;
      padding: 26px 8px;
      a {
        display: inline-block;
        border: 2px solid black;
        padding: 5px 10px;
        min-width: 80px;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
      }
    }
`
const Test = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  position: absolute;
  background-color: rgba(255,255,255,0.9);
  bottom: ${height}px;
  width: 100%;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    li {
      line-height: ${height}px;
      border-bottom: 1px solid #FFF;
      a {
        display: block;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }
`
const Logo = styled.div`
  position: absolute;
  left: 0;
  top: -24px;
`
const NavLink = styled.li`

`
const NavToggle = styled.li`
    cursor: pointer;
    margin-top: -15px;
    margin-right: 10px;
    font-size: 2rem;
    display: inline-block;
`

export default ({logoUrl, links}) => {
  const [showMenu, setShowMenu] = useState(false);
  const locale = useLocale().locale;
  return (
      <Nav>
        <Bar>
          <Test show={showMenu}>
            <ul>
              {links.map((link, key) => <NavLink key={key}><Link
                  to={link.url[locale]}>{link.title[locale]}</Link></NavLink>)}
            </ul>
          </Test>
          <Logo><Link to="/"><img src={logoUrl} alt="Jeanne"/></Link></Logo>
          <NavItems>
            <NavToggle onClick={() => setShowMenu(!showMenu)}><FaBars/></NavToggle>
          </NavItems>
        </Bar>
      </Nav>
  );
}