import React from "react"
import {graphql} from "gatsby";
import styled from '@emotion/styled'
import {useSanity} from "../sanity";
import Gallery from "../components/Gallery/Gallery";
import PageSection from "../components/PageSection";

const builder = (images) => {
  if(!images) {
    return [];
  }
  const getFluidGatsbyImage = useSanity().getFluidGatsbyImage
  return images.map((image, index) => {
    if(!image.asset) return null;
    const fluidProps = getFluidGatsbyImage(image.asset._id, {maxWidth: 1024})
    return {
      src: fluidProps.src,
      width: 10 * fluidProps.aspectRatio,
      height: 10,
      title: image.title,
    }
  }).filter(Boolean);
};

const Section = styled.div`
  margin: ${props => props.theme.section.margin};
`
export default ({images}) => {
  return (
      <PageSection type={'gallery'}>
        <Section>
          <Gallery photos={builder(images)} gutter={6}/>
        </Section>
      </PageSection>
  )
}
export const query = graphql`    
    fragment GallerySectionFragment on SanityGallerySection {
        _key
        _type
        images {
            asset {
                _id
            }
        }
    }
`

