import React from "react"

export default ({siteContext, page, serializers}) => {
  let rawBlocks = page._rawContent
  let sections = (page.content || [])
      .filter(c => !c.disabled)
      .map((c, i) => {
        const Component = serializers.sections.types[c._type];
        const props = {...c, ...{siteContext: siteContext, page: page, raw: rawBlocks[i]}}
        return Component ? <Component key={c._key} {...props} /> :
            <div key={i}>Block of type {c.__typename}/{c._type} not defined</div>
      });
  return <article className={'sections'}>{
    sections.map((section, key) => {
      return section
    })
  }</article>
}