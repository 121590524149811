import React from "react"
import {graphql} from "gatsby"
import classNames from "classnames"
import styled from "@emotion/styled"
import {useTheme} from "emotion-theming"
import Portfolio from "../components/Portfolio";
import PageSection from "../components/PageSection";


const Title = styled.h1`
  position: relative;
  z-index: 0; 
  margin: ${props => props.theme.portfolioPageSection.margin};
  font-family: ${props => props.theme.headerFont};
  font-size: ${props => props.theme.portfolioPageSection.fontSize};
  text-align: center;
  // line-height: 1;
  color: rgba(0,0,0,0.2);
`


export default ({className, portfolio}) => {
  if (!portfolio || !portfolio.artwork) return <div/>;
  const theme = useTheme()
  return (
      <PageSection type={'portfolio'}>
        <Title className={classNames(className, 'title')}>{portfolio.title}</Title>
        <Portfolio portfolio={portfolio} gutter={theme.portfolioPageSection.gutter} />
      </PageSection>
  )
}

export const query = graphql`
    fragment Portfolio on SanityPortfolio {
        title {
            de
            en
        }
        artwork {
            title {
                de
                en
            }
            year
            format
            media {
                de
                en
            }
            photos {
                asset {
                    _id
                    url
                    # Only works with gatsby-source-sanity-transform-images (which causes createPages loop)                    
                    #                    localFile {
                    #                        publicURL
                    #                    }
                }
            }
        }
    }
    fragment PortfolioSectionFragment on SanityPortfolioSection {
        _key
        _type
        portfolio {
            ...Portfolio
        }
    }
`