import React from 'react'
import styled from "@emotion/styled"

/**
 * Notes
 * - equal height columns loose their height when they wrap, so positioning shouldn't be based on calculated height
 */

const Row = styled.div`
`
export default ({style, children}) => (
    <Row className={'row h-100'} style={style}>
      {children}
    </Row>
)