module.exports = {
  "main": {
    "links": [
      {
        "url": {"de": "/journal", "en": "/en/journal"},
        "title": {"de": "Journal", "en": "Journal",}
      },
      {
        "url": {"de": "/bio", "en": "/en/bio"},
        "title": {"de": "Bio", "en": "Bio",}
      },
      {
        "url": {"de": "/kimaku", "en": "/en/kimaku"},
        "title": {"de": "KimaKu", "en": "KimaKu",}
      }
    ],
  },
  "secondary": {
    "links": [
      {
        "url": {"de": "/friends", "en": "/en/friends"},
        "title": {"de": "Friends", "en": "Friends",}
      },
      {
        "url": {"de": "/impressum", "en": "/en/friends"},
        "title": {"de": "Impressum", "en": "Terms Of Service",}
      },
      {
        "url": {"de": "/datenschutz", "en": "/en/privacy-policy"},
        "title": {"de": "Datenschutz", "en": "Privacy Policy",}
      }
    ],
  }
}