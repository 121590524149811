import React from 'react'
import styled from '@emotion/styled'

let inset = '0';

const Overlay = styled.div`
  position: absolute;
  pointer-events: none;
  top: ${inset};
  right: ${inset};
  bottom: ${inset};
  left: ${inset};
`

const Logo = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Title = styled.h1`
  // position: absolute;
  // width: 80%;
  // top: 75%;
  // left: 50%;
  // transform: translate(-50%, -50%);
    text-transform: uppercase;

    text-align: center;
    color: white;
    text-shadow:
        3px 3px 0 #000,
        -1px -1px 0 #000,  
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
       
`

export default ({logo, title, zIndex}) => (
    <Overlay>
      <Logo>
        {logo}
        <Title>{title}</Title>
      </Logo>
    </Overlay>
)