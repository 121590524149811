import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import serializers from '../serializers'
import styled from '@emotion/styled'
import {graphql} from "gatsby";
import PageSection from "../components/PageSection";

const Section = styled.div`
  margin: ${props => props.theme.section.margin};
  ${props => props.theme.mq({
  padding: ['0 20px', '0 20px', '0 100px', '0 200px']
  })};
  text-align: center;
`
const StyledSection = styled.div`
  margin: 10vh 0;
  font-family: ${props => props.theme.bodyFont};
  font-size: 2rem;
  text-align: center;
  
  h1 {
    margin: 0;
    font-family: ${props => props.theme.headerFont};
    font-weight: 700;
    font-size: 4rem; 
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(0,0,0,0.6);
  }
  
  h2 {
      margin: 0;
      font-family: ${props => props.theme.headerFont};
      font-weight: 400;
      font-size: 2rem;
      letter-spacing: 0.22em;
      text-transform: uppercase;
      padding-bottom: 40px;
      color: rgba(0,0,0,0.6);
  }
  
  h3 {
    margin: 24px 0;
    font-family: ${props => props.theme.headerFont};
    font-weight: 700;
    font-size: 2rem;
  }
  h4 {
    margin: 12px 0;
    font-family: ${props => props.theme.headerFont};
    font-weight: 700;
    font-size: 1.6rem;
  }
  
  p {
    margin-bottom: 0;
    padding: 0 24px;
    ${props => props.theme.mq({
  fontSize: ['1.6rem', '2.0rem', '2.0rem', '2.0rem']
})}
  }
`

export default ({raw}) => {
  if (!raw.blocks) {
    return null
  }
  return (
      <PageSection type={'blockcontent'}>
        <Section>
          <StyledSection>
            <BlockContent blocks={raw.blocks} serializers={serializers.blocks}/>
          </StyledSection>
        </Section>
      </PageSection>
  )
}

export const query = graphql`
    fragment BlockContentSectionFragment on SanityBlockContentSection {
        _key
        _type
    }
`
