import React from "react"
import BlockContent from '@sanity/block-content-to-react'
import {Container, Row, Col} from '@shockventures/gatsby-theme-common/src/components/Grid'
import styled from '@emotion/styled'

const Footer = styled.footer`
  position: relative;
  border-top: 10px solid #EFEFEF;
`
const ContentWrapper = styled.div`
`
const Content = styled.div`
  margin: 5vh 0 5vh;
  padding: 24px;
  font-family: ${props => props.theme.bodyFont};
  font-size: 2rem;
  text-align: center;
`
const Logo = styled.div`
`

const ImageCol = styled(Col)`
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  min-height: 500px;
`
//className={'align-items-end'}
export default ({logo, profileImg, bio}) => {
  return (
      <Footer>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <ContentWrapper>
                <Content>
                  <Logo>{logo}</Logo>
                  <BlockContent blocks={bio}/>
                </Content>
              </ContentWrapper>
            </Col>
            <ImageCol xs={12} md={6} url={profileImg.publicURL}/>
          </Row>
        </Container>
      </Footer>
  );
}