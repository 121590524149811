import React from "react"
import {graphql} from "gatsby";
// DO NOT refactor the following two into here, because they won't be available to the page template then
// import SanityProvider from "../sanity/SanityProvider";
// import LocalizationProvider from '../locale/LocalizationProvider';

export const query = graphql`
    fragment BasePage on SanityPage {
        id
        title {
            de
            en
        }
        _rawContent(resolveReferences: { maxDepth: 10 })
        content {
            ...BlockContentSectionFragment
            ...CoverSectionFragment
            ...FriendsSectionFragment
            ...GallerySectionFragment
            ...NewsSectionFragment
            ...PortfolioSectionFragment
        }
    }
`
export default ({siteContext, location, children}) => {
  return children
}
