import React from 'react';
import Link from 'gatsby-link';
import {useLocale} from '../locale'
import {FormattedMessage} from 'react-intl';

/**
 * @todo refactor hard-coded language names Deutsch/English into config
 */
export default ({className}) => {
  const locale = useLocale()
  // console.log(locale)
  return (
      <ul className={className}>
        {locale.languages.map(language =>
            <li key={language.langKey} className={language.selected ? 'selected' : ''}>
              <Link to={language.link}  style={{color: 'black'}}>
                {{de:'Deutsch', en:'English'}[language.langKey]}
              </Link>
            </li>
        )}
      </ul>
  );
};
