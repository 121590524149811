import React from "react"
import {StaticQuery, graphql} from "gatsby"
//
import {useLocale} from "@shockventures/gatsby-theme-common"
import Layout from "@shockventures/gatsby-theme-common/src/components/Layout";
import PageSections from "@shockventures/gatsby-theme-common/src/components/PageSections";
//
import serializers from "../serializers";
//
import Footer from "./Footer";
import BioFooter from "./BioFooter";

/**
 * @todo refactor menu-logo into cms
 * @todo refactor profile-picture into cms
 */
export default ({siteContext, data}) => {
  let page = data.page
  let locale = useLocale()
  return (
  <StaticQuery
      query={graphql`
              query {
                artist: sanityArtist(id: {eq: "-05346c5f-1783-5148-b456-085c24d7a456"}) {
                    ...Artist
                }
#                studio: sanityStudio(artists: {elemMatch: {artist: {id: {eq: "05346c5f-1783-5148-b456-085c24d7a456"}}}}) {
#                  ...Studio
#                }
                logoImage: file(name: {eq: "menu-logo"}) {
                    ...publicUrl
                }
                footerProfile: file(name: {eq: "profile-picture"}) {
                  publicURL
                  childImageSharp {
                      fluid(quality: 70, maxWidth: 700) {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
                }
              }
          `}
      render={data => {
        data = locale.localizeData(data)
        return (
            <Layout siteContext={siteContext} page={page}>
              <PageSections siteContext={siteContext} page={page} serializers={serializers}/>
              <BioFooter logo={<img src={data.logoImage.publicURL} alt={'Jeanne'}/>}
                         profileImg={data.footerProfile}
                         bio={data.artist._rawShortBio}
              />
              <Footer siteContext={siteContext}/>
            </Layout>
        )
      }}/>)
}