import React from "react"
import styled from "@emotion/styled";
import {animateScroll as scroll} from "react-scroll";

const size = '1.5rem'
const width = 200;

const Button = styled.a`
    position: absolute;
    bottom: ${props => props.theme.cover.inset ?? 0};
    left: 50%;
    width: ${width}px;
    //height: 64px;
    text-align: center;
    margin-left: -${width / 2}px;
    opacity: 0.8;
    cursor: pointer;
    transition-property: transform, opacity;
    transition: transform .75s ease,opacity .75s ease;
    transition-delay: 1.5s;
    transform: translateY(0);
    color: white !important;
    font-family: ${props => props.theme.headerFont};
    font-size: ${size};
    letter-spacing: 2px;
    text-transform: uppercase;
    &:hover {
    }
    picture {
      display: block;
      position: relative;
      margin: ${size} auto 0;
    }
     svg {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         color: white;
         font-size: ${size};
     }
`

const scrollToRef = (ref) => {
  scroll.scrollTo(ref.current.offsetTop)
}

export default ({className, icon, scrollTo, children}) => {
  return (
      <Button className={className || ''} onClick={() => scrollToRef(scrollTo)}>
        {children}
        <picture>{icon}</picture>
      </Button>
  )
}
