import React from "react"
import Gallery from "../components/Gallery/Gallery"
import {useSanity} from "../sanity";

export const builder = (portfolio, getFluidGatsbyImage) => {
  if (!portfolio.artwork) {
    return [];
  }
  return portfolio.artwork.map(artwork => {
    if (!artwork.photos || !artwork.photos[0] || !artwork.photos[0].asset) {
      return null;
    }
    const fluidProps = getFluidGatsbyImage(artwork.photos[0].asset._id, {maxWidth: 1024})
    return {
      src: fluidProps.src,
      width: 10 * fluidProps.aspectRatio,
      height: 10,
      title: `${artwork.title || ''}, ${artwork.format || ''}, ${artwork.media || ''}, ${artwork.year || ''}`,
    }
  }).filter(Boolean)
};

export default ({portfolio, gutter}) => {
  if (!portfolio || !portfolio.artwork) return <div/>;
  const photos = builder(portfolio, useSanity().getFluidGatsbyImage)
  // const fluidProps = getFluidGatsbyImage(props.Portfolio.artwork[0].photos[0].asset._id, {maxWidth: 1024}, sanityConfig)
  return (
      <Gallery photos={photos} gutter={gutter}/>
  )
}