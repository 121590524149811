import React from 'react'
import Block from "./blocks/Block";
import Test from "./blocks/Test";
import Link from "./components/Link";
//
import PortfolioSection from "./sections/PortfolioSection"
import BlockContentSection from "./sections/BlockContentSection";
import FriendsSection from "./sections/FriendsSection";
import GallerySection from "./sections/GallerySection";
import NewsSection from "./sections/NewsSection";
import CoverSection from "./sections/CoverSection";

const serializers = {
  blocks: {
    types: {
      block: Block,
      localeBlockContent: Test,
      link: Link,
    },
    marks: {
      link: (props) => (
          <Link to={props.mark.href}>{props.children}</Link>
      )
    }
  },
  sections: {
    types: {
      coverSection: CoverSection,
      portfolioSection: PortfolioSection,
      blockContentSection: BlockContentSection,
      friendsSection: FriendsSection,
      gallerySection: GallerySection,
      newsSection: NewsSection,
    },
  }
}

export default serializers
