import React from 'react'
import Div100vh from 'react-div-100vh'
import styled from "@emotion/styled"

const Cover = styled.div`
  position: relative;
  height: 100%;
`
const CoverInner = styled.div`
  height: 100%;
  padding: ${props => props.inset || 0};
`
export default ({className, inset, children}) => (
    <Div100vh>
      <Cover className={className}>
        <CoverInner inset={inset}>
          {children}
        </CoverInner>
      </Cover>
    </Div100vh>
)