import React from "react"
import Masonry from 'react-masonry-css'
import styled from "@emotion/styled"

const ArticleList = styled(Masonry)`
&.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -${props => props.gutter}px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: ${props => props.gutter}px; /* gutter size */
  background-clip: padding-box;
}
 
/* Style your items */
.my-masonry-grid_column > section { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: ${props => props.gutter}px;
}
`

const breakpoints = {
  default: 3,
  1024: 3,
  768: 2,
  500: 1
}

export default ({children, gutter }) => {
  return (
      <ArticleList
          gutter={gutter}
          breakpointCols={breakpoints}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
        {children}
      </ArticleList>
  )
}