import React from 'react'
import styled from '@emotion/styled'
import {useSanity} from "../../sanity";
import {useLocale} from "../../locale";
import ImageSliderFlickity from "./ImageSliderFlickity";
import Img from 'gatsby-image';

const Article = styled.section`
  position: relative;
  outline: 1px solid #CCC;
  background-color: white;
  padding: 24px;
`
const DateRange = styled.div`
`
const Title = styled.h2`
  margin: 6px 0 4px;
  font-family: ${props => props.theme.headerFont};
  //font-size: 24px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
`
const SubTitles = styled.div`
  font-weight: 700;
  text-transform: uppercase;
`
const Image = styled.div`
  margin: 12px 0;
`;
const Body = styled.div`
  margin: 12px 0;
`;

export default ({article}) => {
  const locale = useLocale()
  const getFluidGatsbyImage = useSanity().getFluidGatsbyImage
  return (
      <Article>
        <DateRange>{locale.localizeDate(article.publishedAt)}</DateRange>
        <Title>{article.title}</Title>
        <SubTitles>{article.subTitle}</SubTitles>
        <Image>
          <ImageSliderFlickity>
            {article.images ? article.images.map((image, key) => (
                <Img key={key} fluid={getFluidGatsbyImage(image.asset.id, 500)}/>)) : null}
          </ImageSliderFlickity>
        </Image>
        <Body>{article.content}</Body>
      </Article>
  )
}