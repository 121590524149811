import React from 'react'
import styled from '@emotion/styled'
import classNames from 'classnames'
import {FaInstagram} from "react-icons/fa";
import Link from './Link'

const Nav = styled.nav`
  text-align: ${props => props.align};
  font-size: ${props => props.fontSize};
`

export default ({className, connectedAccounts}) => (
    <Nav className={classNames(className)} align={'center'} fontSize={'2rem'}>
        {connectedAccounts.map((connectedAccount, key) => {
            switch(connectedAccount.screenname) {
                case 'Instagram':
                    return <Link key={key} to={connectedAccount.profileUrl}><FaInstagram/></Link>
            }
        })}
    </Nav>
)