import React from 'react'
import {useLocale} from '../locale'
import {Link as InternalLink} from 'gatsby'
import ExternalLink from "./ExternalLink";
import styled from '@emotion/styled'

const StyledInternalLink = styled(InternalLink)`
  color: ${props => props.theme.linkColor ?? props.theme.textColor};
  text-decoration: ${props => props.theme.linkDecoration ?? 'underline'};
`

const StyledExternalLink = styled(ExternalLink)`
  color: ${props => props.theme.linkColor ?? props.theme.textColor};
  text-decoration: ${props => props.theme.linkDecoration ?? 'underline'};
`

const isInternalURL = (to) => {
  try {
    const url = new URL(to, window.location.origin);
    return url.hostname === window.location.hostname;
  } catch {
    return false;
  }
};

export default ({to, children}) => {

  // const test = useLocale().localizeUrl(to);
  // console.log("URL", test)

  if(!to) {
    // throw "Invalid to"
    console.warn('invalid uri')
    return null
  }

  return (
      <React.Fragment>{
        isInternalURL(to)
            ? (<StyledInternalLink to={useLocale().localizeUrl(to).link}>{children}</StyledInternalLink>)
            : (<StyledExternalLink to={to}>{children}</StyledExternalLink>)}
      </React.Fragment>
  )
}