import React from 'react'
import News from "../components/News/News";
import EventArticle from "../components/News/EventArticle";
import DummyArticle from "../components/News/DummyArticle";
import PostArticle from "../components/News/PostArticle";
import {graphql} from "gatsby";
import PageSection from "../components/PageSection";

export const rawArticleSerializer = function (articles) {
  return !articles ? null : articles.map((article, key) => {
    if (!article.id) return null
    switch (article._type) {
      case 'exhibition':
        return <EventArticle key={key} article={article}/>
      case 'post':
        return <PostArticle key={key} article={article}/>
      default:
        return <DummyArticle/>;
    }
  })
}
export default ({title, articles, raw}) => {
  return (
      <PageSection type={'news'}>
        <News title={raw.title}>{rawArticleSerializer(raw.articles)}</News>
      </PageSection>
  )
}
export const query = graphql`
    fragment NewsSectionFragment on SanityNewsSection {
        _key
        _type
    }
`