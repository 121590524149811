import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import {StaticQuery, graphql} from "gatsby"
//
import {useSanity} from "@shockventures/gatsby-theme-common";
import {
  Carousel,
} from "@shockventures/gatsby-theme-common/src/components/Cover"
//
import Nav from "../components/Nav";

const Cover = styled.div`
  position: relative;
  margin: 12px 12px 6px 12px;
  height: 500px;
`
const Slide = styled.div`
  width: 100%;
  height: 100%;
`
export default ({siteContext, covers}) => {
  const getFluidGatsbyImage = useSanity().getFluidGatsbyImage;
  /**
   * @todo consider refactoring into cms
   */
  return <StaticQuery
      query={graphql`
        query {
            logoImage: file(name: {eq: "menu-logo"}) {
                ...publicUrl
            }
        }
      `} render={data => (
      <Cover>
        <Carousel fade={true}>{covers.map((cover, key) => {
          const fluidProps = cover.photos && cover.photos[0] ? getFluidGatsbyImage(cover.photos[0].asset._id, {maxWidth: 1400}) : null
          return fluidProps ? <Slide key={key}><Img fluid={fluidProps} durationFadeIn={1000}/></Slide> : null
        })}</Carousel>
        <Nav logoUrl={data.logoImage.publicURL} links={siteContext.siteLayout.main.links}/>
      </Cover>
  )}/>
}

export const query = graphql`
    fragment CoverSectionFragment on SanityCoverSection {
        _key
        _type
        covers {
            photos {
                asset {
                    _id
                    url
                    # Only works with gatsby-source-sanity-transform-images (which causes createPages loop)
                    #                    localFile(width: 1400) {
                    #                        publicURL
                    #                        childImageSharp {
                    #                            fluid {
                    #                                ...GatsbyImageSharpFluid
                    #                            }
                    #                        }
                    #                    }
                }
            }
        }
    }
`